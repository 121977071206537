import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class Points {
    constructor() {
        this.http = new HttpHandler();
    }

    wcPlay(data) {
        let url = '/api/wcPlay/payment';
        return this.http.post(url, data, HttpConstant.FORM_DATA_REQ);
    }


    getData(data) {
        let url = '/api/points/datas';
        return this.http.get(url, data, HttpConstant.FORM_DATA_REQ);
    }

    getUserPoint() {
        let url = '/api/points/userPoint';
        return this.http.get(url, null, HttpConstant.FORM_DATA_REQ);
    }

}
