<template>
  <div>
    <el-descriptions class="margin-top" :column="2" size="medium" border style="margin-top: 20px">
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          用户名
        </template>
        {{ usrData.usrName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-finished"></i>
          剩余点数
        </template>
        <el-tag type="warning">{{ usrData.usrPoint }}</el-tag>
      </el-descriptions-item>
    </el-descriptions>

    <el-row type="flex" class="row-bg" justify="space-around">
      <el-col :span="24" style="line-height: 30px">
        <el-switch @change="getData(1, 10)"
                   v-model="findType"
                   active-text="消费记录"
                   inactive-text="充值记录">
        </el-switch>
        <el-button type="primary" size="mini" style="float: right" @click="dialogVisible = true">充值</el-button>
      </el-col>
    </el-row>
    <el-divider/>
    <el-table
        :data="data.list"
        style="width: 100%"
        :height= ULHeight>
      <el-table-column prop="poiType" label="类型"/>
      <el-table-column prop="poiTime" label="日期"/>
      <el-table-column prop="poiAmount" label="点数"/>
      <el-table-column v-if="!findType" prop="poiSource" label="订单号"/>
      <el-table-column v-else prop="poiSource" label="来源"/>
    </el-table>
    <el-pagination style="text-align: center"
                   background
                   @current-change="handleCurrentChange"
                   :pager-count="5"
                   :page-size="data.pageSize"
                   layout="prev, pager, next"
                   :total="data.total">
    </el-pagination>


    <el-dialog
        title="充值橙点"
        :visible.sync="dialogVisible"
        width="90%"
        :before-close="handleClose">
      <div style="text-align: center">
        <el-form ref="form">
          <el-form-item label="金额">
            <el-input-number v-model="money" :min="0.1" :precision="1" :step="0.1" :max="500"
                             :controls="true"/>
          </el-form-item>
          <el-form-item label="橙点">
            <el-tag type="warning">{{ money * 10 }}</el-tag>
          </el-form-item>
          <el-form-item label="支付">
            <div>
              <p>微信扫码支付</p>
              <a :href="wcUrl" style="margin-left: 35px"><img :src="qrCodeData"/></a>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="wcPlay">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import QRCode from 'qrcode';
import Points from "../../request/points";

export default {
  name: "points",
  data() {
    return {
      ask: new Points(),
      findType: false,
      dialogVisible: false,
      money: 1,
      qrCodeData: "",
      data: {},
      currentPage4: 4,
      usrData: {},
      wcUrl: "#",
      ULHeight: (window.innerHeight - 74 - 50 - 49 - 32 - 44 - 26) + 'px'
    }
  },
  mounted() {
    this.ask.getUserPoint().then(res => {
      this.usrData = res
    })
    this.getData(1, 10);
  },
  methods: {
    getData(pageNum, pageSize) {
      let data = {
        "type": this.findType ? "消费" : "充值",
        "pageNum": pageNum,
        "pageSize": pageSize
      }
      this.ask.getData(data).then(res => {
        this.data = res
      })
    },
    wcPlay() {
      let data = {
        "title": "橙点充值",
        "tradeNo": "cs_" + new Date().getTime(),
        "money": this.money * 100
      }
      this.ask.wcPlay(data).then(res => {
        if (res.code === 'success') {
          this.wcUrl = res.msg
          this.generateQRCode(res.msg);
          console.log(res.msg)
        }
      })
    },
    generateQRCode(data) {
      QRCode.toDataURL(data)
          .then(url => {
            this.qrCodeData = url;
          })
          .catch(error => {
            console.error('生成二维码失败', error);
          });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    handleCurrentChange(val) {
      this.getData(val, 10)
    }
  },
  created() {
    window.onresize = () => {
      this.ULHeight = (window.innerHeight - 74 - 50 - 49 - 32 - 44 - 26) + 'px'
    };
  }
}
</script>

<style scoped>
.row-bg {
  text-align: center;
  margin-top: 20px;
}
</style>